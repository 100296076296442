<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body class="envolvente-device">
          <b-card-body>
            <div class="envolvente-top">
              <span class="envolvente-icon">
                <feather-icon
                  icon="CreditCardIcon"
                />
              </span>
              <span class="text-envolvente">
                  Envolvente
              </span>
              <div class="envolvente-right">
                <b-form-group
                >
                  <v-select
                    class="date-select"
                    placeholder="day / week / month"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/cooker.png" alt="">
                </span>
                <span class="text-int">
                    Temperatura ambiente e Humidade relativa
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-envolvente-temp-amb />
              <vue-slider
                v-model="value"
                :direction="direction"
                class="mb-2 p-0 range-temp"
              />
              <vue-slider
                v-model="value2"
                :direction="direction"
                class="mb-2 p-0 range-temp"
              />
            </b-col>
            <b-col lg="3" class="temp-alert">
              <div>
                <feather-icon
                  class="temp-alert-icon"
                  icon="AlertCircleIcon"
                />
                <span class="text-alert">ups</span>
                <span class="text-estado">estado do equipamento</span>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row class="abertura-portas">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-door">
                  <img src="@/assets/images/icons/open-door.png" alt="">
                </span>
                <span class="text-int">
                    Abertura de portas
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="3" class="portas-text">
              <div class="portas-text">
                  <span class="portas-top-text">+ 04 un.</span>
                <div class="portas-text-middle">
                  <span class="portas-mid-text">20</span>
                  <img src="@/assets/images/icons/consumo-red.png" alt="">
                </div>
                <span class="portas-bottom-text">Abertura de portas</span>
              </div>
            </b-col>
            <b-col lg="3" class="portas-text">
              <div class="portas-text">
                  <span class="portas-top-text green">-00:02 min.</span>
                <div class="portas-text-middle">
                  <span class="portas-mid-text">00:01
                    <span class="mid-text-sec">:40</span>
                  </span>
                  <img src="@/assets/images/icons/consumo-green.png" alt="">
                </div>
                <span class="portas-bottom-text">Tempo total</span>
              </div>
            </b-col>
            <b-col lg="3" class="info-btn">
              <b-button
                class="btn-sm temp-info-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.left="'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'"
                variant="none"
              >
                <feather-icon
                  class="temp-info-icon"
                  icon="AlertCircleIcon"
                />
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/condensador.png" alt="">
                </span>
                <span class="text-int">
                    Eficácia do condensador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-envolvente-top :data="data.salesChart"/>
            </b-col>
            <b-col lg="3">
              <div class="text-legenda d-inline-flex align-items-center">
                <feather-icon
                icon="CircleIcon"
                class="circle-icon-portas mr-50"
                />
                <span>Intervalo de temperatura do gás à entrada e saída</span>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/condensador.png" alt="">
                </span>
                <span class="text-int">
                    Sobreaquecimento do evaporador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-envolvente-sobreaquec />
            </b-col>
            <b-col lg="3" class="sobreaquec-right">
              <div class="img-sobreaquec">
                <img src="@/assets/images/img/estado-do-condensador.png" alt="">
              </div>
              <div class="info-btn">
                <b-button
                  class="btn-sm temp-info-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.left="'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'"
                  variant="none"
                >
                  <feather-icon
                    class="alert-info-icon"
                    icon="BellIcon"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import store from '@/store/index'
import DeviceInfoEnvolventeTempAmb from '../Envolvente/DeviceInfoEnvolventeTempAmb.vue'
import DeviceInfoEnvolventeSobreaquec from '../Envolvente/DeviceInfoEnvolventeSobreaquec.vue'
import DeviceInfoEnvolventeTop from '../Envolvente/DeviceInfoEnvolventeTop.vue'


export default {
  components: {
    VueSlider,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeTempAmb,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoEnvolventeTop
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
      value: 10,
      value2: 90,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>