<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        type="scatter"
        height="200"
        :options="performaceEvapChartData.scatterChart.chartOptions"
        :series="performaceEvapChartData.scatterChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BButtonGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import performaceEvapChartData from './performaceEvapChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      performaceEvapChartData,
    }
  },
}
</script>