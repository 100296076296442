<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        height="242"
        :options="performaceChartData.lineChartSimple.chartOptions"
        :series="performaceChartData.lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import performaceChartData from './performaceChartData'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      performaceChartData,
    }
  },
}
</script>
