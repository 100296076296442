<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body class="performace-device">
          <b-card-body>
            <div class="performace-top">
              <span class="performace-icon">
                <feather-icon
                  icon=""
                />
              </span>
              <span class="text-performace">
                  Performance
              </span>
              <div class="performace-right">
                <b-form-group
                >
                  <v-select
                    class="date-select"
                    placeholder="day / week / month"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-energy">
                  <img src="@/assets/images/icons/energy.png" alt="">
                </span>
                <span class="text-int">
                    Consumo de energia
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-performace-cons-energ />
            </b-col>
            <b-col lg="3" class="kwh-right">
              <div class="circle-kwh">
                <span>5,5 kWh</span>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-potencia">
                  <img src="@/assets/images/icons/potencia.png" alt="">
                </span>
                <span class="text-int">
                    Potência
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-performace-potencia />
            </b-col>
            <b-col lg="3" class="kwh-right">
              <div class="circle-kwh">
                <span>10,0 kWh</span>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="6">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/freeze.png" alt="">
                </span>
                <span class="text-int">
                    Ventilação do evaporador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
              <device-info-performace-evap/>
            </b-col>
            <b-col lg="6">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/freeze.png" alt="">
                </span>
                <span class="text-int">
                    Ventilação do evaporador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
              <device-info-performace-evap/>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/vapor.png" alt="">
                </span>
                <span class="text-int">
                    Eficácia do evaporador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-performace-top :data="data.salesChart"/>
            </b-col>
            <b-col lg="3">
              <div class="text-legenda d-inline-flex align-items-center">
                <feather-icon
                icon="CircleIcon"
                class="circle-icon-portas mr-50"
                />
                <span>Intervalo de temperatura do gás à entrada e saída</span>
              </div>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/vapor.png" alt="">
                </span>
                <span class="text-int">
                    Sobreaquecimento do evaporador
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col lg="6">
              <device-info-envolvente-sobreaquec />
            </b-col>
            <b-col lg="3" class="sobreaquec-right">
              <div class="img-sobreaquec">
                <img src="@/assets/images/img/estado-do-condensador.png" alt="">
              </div>
              <div class="info-btn">
                <b-button
                  class="btn-sm temp-info-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.left="'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'"
                  variant="none"
                >
                  <feather-icon
                    class="alert-info-icon red"
                    icon="BellIcon"
                  />
                  <span class="notification">1</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DeviceInfoEnvolventeSobreaquec from '../Envolvente/DeviceInfoEnvolventeSobreaquec.vue'
import DeviceInfoPerformacePotencia from './DeviceInfoPerformacePotencia.vue'
import DeviceInfoPerformaceConsEnerg from './DeviceInfoPerformaceConsEnerg.vue'
import DeviceInfoPerformaceEvap from './DeviceInfoPerformaceEvap.vue'
import DeviceInfoPerformaceTop from './DeviceInfoPerformaceTop.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoPerformacePotencia,
    DeviceInfoPerformaceConsEnerg,
    DeviceInfoPerformaceEvap,
    DeviceInfoPerformaceTop,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>